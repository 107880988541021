import { MA_APP_IDS } from '@wix/members-area-integration-kit';

export enum Event {
  DeleteApp = 'deleteReferralsApp',
  Manage = 'manageReferrals',
}

export enum GfppAction {
  ManageProgram = 'manageProgram',
  OpenElementsPanel = 'openElementsPanel',
  OpenLayoutPanel = 'openLayoutPanel',
  OpenSwitchStatePanel = 'openSwitchStatePanel',
}

export const REQUIRED_MEMBERS_AREA_APPS = [MA_APP_IDS.MY_REWARDS];

export const BM_URL_INDEX_PAGE = '/referrals';
export const BM_URL_MANAGE_PAGE = '/referrals/manage';
export const BM_URL_MANAGE_PAGE_EMAILS_SECTION = '/referrals/manage?showEmails=true';

export const SETUP_REFERRAL_PROGRAM_HELP_ID = 'caf9b39e-39eb-4e16-9b42-c4e136828c3f';
